import './home_content.css'

import grassfieldLogo from '../images/grassfield_logo.png'
import purdueP from '../images/purdue_p.png'
import gitHub_logo from '../images/gitHub.png'
import react_logo from '../images/react.png'
import cdyne_logo from '../images/cdyne.png'
import ETS_logo from '../images/ETS.jpg'
import usSeal from '../images/usSeal.png'
import euro_logo from '../images/european_autowerks.png'
import zetier_logo from '../images/zetier.png'
import { useState } from 'react'

const Purdue = <a href="https://engineering.purdue.edu/ECE" target="_blank" rel="noopener noreferrer">Purdue University</a>
const GitHub = <a href="https://github.com/g-hurst" target="_blank" rel="noopener noreferrer">GitHub</a>
const React = <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">ReactJS</a>
const STEM_academy = <a href="https://www.cpschools.com/o/gfh/page/governors-stem-academy" target="_blank" rel="noopener noreferrer">Governor’s STEM Academy</a>

// Beginning of the education content section
const purdue_content = () => {
  return <div className="educationContent">
    <ul>
      <ul>
        <li>BS in Computer Engineering</li>
        <li>GPA: 3.73/4.00</li>
        <li>Projected Graduation: May 2025</li>
      </ul>
      <div className="eduSubsections">
        <div className="eduSection">
          <h4>Activities & Experiences</h4>
          <ul>
            <div className='edu_purdue_acttivities_wrapper'>
              <div className='edu_purdue_acttivities_left'>
                <i>Purdue Rifle Team: </i> <br />
                (Fall 2021 - Current)
              </div>
              <div className='edu_purdue_acttivities_right'>
                Member of the Purdue Rifle Team and trip safety leader on away competitions. 
                Competes monthly against other schools in both Olympic Smallbore and Air Rifle.
                Helps run Purdue Rifle and Pistol Club events. 
              </div>
            </div>
            <div className='edu_purdue_acttivities_wrapper'>
              <div className='edu_purdue_acttivities_left'>
                <i>Undergraduate Research Assistance: </i> <br />
                (Spring 2023)
              </div>
              <div className='edu_purdue_acttivities_right'>
                Explored current neural network exploitation techniques and assisted in exploitation development. 
                Implemented trusted environment execution for AI/ML functions.
                Automated software verification process to run comprehensive tests and validate results.
              </div>
            </div>
            <div className='edu_purdue_acttivities_wrapper'>
              <div className='edu_purdue_acttivities_left'>
                <i>Purdue Electric Racing: </i> <br />
                (Fall 2021)
              </div>
              <div className='edu_purdue_acttivities_right'>
                Developed embedded C software for microcontrollers that sends data to motor controllers.
                Defined functions to setup motor controllers, change modes, and control power to nearest 0.1%.
                Created documentation for microcontroller setup and function use.
              </div>
            </div>
            <div className='edu_purdue_acttivities_wrapper'>
              <div className='edu_purdue_acttivities_left'>
                <i>Honors College Leadership Retreat:</i> <br />
                (Fall 2021)
              </div>
              <div className='edu_purdue_acttivities_right'>
                Attended over 10 hours of leadership and teaming workshops. 
                Practiced abilities to effectively interact, instruct, and communicate with small and large groups.
                Practiced self-reflection, working collectively toward a greater vision, and commitment to inclusion and equity.
              </div>
            </div>
          </ul>
        </div>
        <div className="eduSection">
          <h4>Relavant Coursework</h4>
          <ul>
            <li>ECE 50863: Computer Network Systems (graduate level)</li>
            <li>ECE 46900: Operating Systems Engineering</li>            
            <li>ECE 36800: Data Structures</li>
            <li>ECE 30200: Probabilistic Methods in Electrical and Computer Engineering</li>
            <li>ECE 30100: Signals and Systems</li>
            <li>ECE 26400: Advanced C Programming</li>
            <li>ECE 20875: Python for Data Science</li>
            <li>ECE 27000: Introduction to Digital System Design</li>
            <li>ENGR 16100 & 16200: Honors Introduction to Engineering Design</li>
            <li>MA 26600: Linear Algebra</li>
          </ul>
        </div>
      </div>
    </ul>
  </div>
}
const hs_content = () => {
  return <div className="">
    <ul>
      <li>4.867/4.0 GPA</li>
      <li>Graduated 10th in a class of 564 students</li>
      <li>Selected from over 500 students to be in the {STEM_academy} Engineering and Technology Pathway</li>
    </ul>
  </div>
}

// Beginning of the work experience content section
const work_gov23 = () => {
  return <div>
    <ul>
      <li>Developed and deployed real time monitoring scripts across a global network of mission critical systems. </li>
      <li>Provided remote support for systems enabling continuous collection and processing of signal intelligence worldwide. </li>
      <li>Operated on a joint IC mission satisfying foreign intelligence needs for the greater IC and second party partners. </li>
    </ul>
  </div>
}

const work_zetier = () => {
  return <div>
    <ul>
      <li>Discovered a zero-day vulnerability on the latest version of Android phones and delivered a proof of concept. </li>
      <li>Added fuzzing support for custom kernel drivers in an emulated environment.  </li>
      <li>Reverse engineered Android ROMs to automate a metadata extraction process for over 500 unique devices.  </li>
    </ul>
  </div>
}

const work_gov22 = () => {
  return <div>
    <ul>
      <li>Developed machine learning solutions to perform multi label classification of large natural language text datasets. </li>
      <li>Created statistical visualizations to quantify and compare machine learning model designs.  </li>
      <li>Performed dimension reduction and clustering to increase model efficiency.  </li>
    </ul>
  </div>
}

const work_csta = () => {
  return <div>
    <ul>
      <li>Teaching assistant for CS15900 (C Programming for Engineers).</li>
      <li>Leads a weekly lab of 30 students. </li>
      <li>Hosts biweekly office hours for additional student support. </li>
    </ul>
  </div>
}
const work_euro = () => {
  return <div>
    <ul>
      <li>Assisted technicians with vehicle repair.</li>
      <li>Maintained vehicle repair machines and kept updated inventory.</li>
      <li>Found and implemented shop improvements to enhance organization and productivity.</li>
    </ul>
  </div>
}

const work_cdyne = () => {
  return <div>
    <ul>
      <li>Worked with a group of system administrators to write scripts and troubleshoot network issues.</li>
      <li>Upgraded hardware and installed software on over 200 company computers to boost productivity.</li>
      <li>Aided with help desk, asset inventory, and computer maintenance.</li>
    </ul>
  </div>
}

const work_ets = () => {
  return <div>
    <ul>
      <li>Performed concrete, grout, and mortar tensile strength tests for multimillion-dollar construction projects.</li>
      <li>Assisted with various civil and geotechnical engineering tests.</li>
      <li>Shadowed civil engineers on project sites.</li>
    </ul>
  </div>
}

// Beginning of the projects content section
const git_content = () => {
  return <div>
    <p>As a hobby, I like to complete small personal projects that I find interesting.
      Many of my projects can be found on my {GitHub}.</p>
  </div>
}

const this_page_content = () => {
  return <div>
    <p>This webpage has been a great learning experience.
      I fully developed this page myself which allowed me to get some great experience with {React}!</p>
  </div>
}

const edu_blocks = [{ title: 'Purdue University', date: '2021 - Current', image: purdueP, content: purdue_content() },
                     { title: 'Grassfield High School', date: '2017 - 2021', image: grassfieldLogo, content: hs_content() }]
const proj_blocks = [{ title: 'This Page', image: react_logo, content: this_page_content() },
                      { title: 'GitHub', image: gitHub_logo, content: git_content() }]
const work_blocks =  [{title: 'US Federal Government: Computer Science Intern', date: 'September 2023 - December 2023', image: usSeal, content: work_gov23() }, 
                      {title: 'Zetier: Cyber Intern', date: 'May 2023 - September 2023', image: zetier_logo, content: work_zetier() },
                      {title: 'US Federal Government: Computer Science Intern', date: 'May 2022 - August 2022', image: usSeal, content: work_gov22() },
                      { title: 'Purdue University: Undergraduate Teaching Assistant', date: 'Jan 2022 - Current', image: purdueP, content: work_csta() },
                      { title: 'European Autowerks: Shop Assistant', date: 'Jun 2020 - Aug 2021', image: euro_logo, content: work_euro() },
                      { title: 'CDYNE Corporation: IT & Server Management Intern', date: 'Jun 2020 - Oct 2020', image: cdyne_logo, content: work_cdyne() },
                      { title: 'Engineering and Testing Services: Summer Intern', date: 'Jun 2019 - Aug 2019', image: ETS_logo, content: work_ets() }]

export { Purdue, edu_blocks, proj_blocks, work_blocks }