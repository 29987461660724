import './Home.css'
import profileTiny from '../images/profileTiny.JPG'
import { Purdue, edu_blocks, proj_blocks, work_blocks } from '../static_content/home_content'
import { SectionContainer } from '../components/SectionContainer'

const Home = () => {

  return (
    <div>
      {/* Beginning of the the top section of the page */}
      <div className="topSection">
          <div className="pictureSeciton">
            <div className="pfp">
              <img src={profileTiny} alt="" />
            </div>
            <div className="pictureText">
              <b>Status: </b> Computer Engineering student at {Purdue}<br />
              <b>Contact: </b> <a href="mailto: gahurst@purdue.edu">gahurst@purdue.edu</a> <br />
              <p>I am currently a junior at Purdue University studying Computer Engineering.
              My enthusiasm for learning drives my passion for problem solving and software development.  
              I am particulary interested in (but not limited to), cyber development, vulnerability research,
              and data exploitation. Eager to expand my horizons, my objective is to explore the diverse facets 
              of computer engineering through professional internships, aiming to enhance 
              existing and build new skills while pursuing my degree.</p>
            </div>
          </div>
      </div>

      <SectionContainer title='Education' content={edu_blocks}/>
      <SectionContainer title='Work Experience' content={work_blocks}/>
      <SectionContainer title='Projects' content={proj_blocks}/>
    </div>
  );
}

export default Home;