import React from 'react';
import './Connect.css';
import linkedIn from '../images/linkedIn.png';
import gitHub from '../images/gitHub.png';

const Contact = () => {
  const linkedInLink = "https://www.linkedin.com/in/garrett-a-hurst/";
  const gitHubLink = "https://github.com/g-hurst";

  return (
    <div className="contactButtons">
      <a href={linkedInLink}
        target="_blank"
        rel="noopener noreferrer">
        <img src={linkedIn} alt="" href={linkedInLink} />
        </a>
      <a href={gitHubLink}
        target="_blank"
        rel="noopener noreferrer">
        <img src={gitHub} alt="" href={gitHubLink} />
      </a>
    </div>
  );
};

export default Contact;