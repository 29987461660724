import React from 'react';
import './Navbar.css'
import Connect from '../components/Connect'

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="myName">
        <h1>Garrett Hurst</h1>
      </div>
      <div className="contact">
          <Connect className="contactWidget" />
      </div>
    </nav>
  );
}

export default Navbar;